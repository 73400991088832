const logo = (
   <svg
      width='42'
      height='50'
      viewBox='0 0 42 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
   >
      <path
         d='M27.9281 8.06777L27.9281 16.6099C27.9281 17.2906 28.4751 17.8436 29.1512 17.8436L34.3643 17.8436L34.3643 24.0575L27.8399 24.0575C24.4863 24.0575 21.7679 21.3154 21.7679 17.9326L21.7679 2.64419C21.7679 1.84619 22.4095 1.19902 23.2006 1.19902L39.3627 1.19902L39.3627 7.41287L28.5786 7.41287C28.2195 7.41287 27.9294 7.7068 27.9294 8.06777L27.9281 8.06777Z'
         fill='black'
      />
      <path
         d='M20.172 7.97254C19.979 4.10114 16.7225 1.02129 12.7363 1.02129L9.06705 1.02129C5.07062 1.02129 1.81417 4.10114 1.62119 7.97254C1.61224 8.08857 1.61224 8.21233 1.61224 8.33609L1.61224 16.7428C1.61224 16.8846 1.61224 17.0355 1.63014 17.1773C1.84996 21.0126 5.09746 24.0576 9.06705 24.0576L12.7363 24.0576C16.697 24.0576 19.9445 21.0126 20.1732 17.1773C20.1822 17.0355 20.1822 16.8846 20.1822 16.7428L20.1822 8.33609C20.1822 8.21233 20.1822 8.08728 20.1732 7.97254L20.172 7.97254ZM11.2039 18.0127L10.5969 18.0127C9.03894 18.0127 7.7724 16.7699 7.7724 15.2435L7.7724 9.84572C7.7724 8.31031 9.04022 7.07656 10.5969 7.07656L11.2039 7.07656C12.7619 7.07656 14.0195 8.31031 14.0195 9.84572L14.0195 15.2435C14.0195 16.7699 12.7606 18.0127 11.2039 18.0127Z'
         fill='black'
      />
      <path
         d='M34.1765 25.521L34.2263 39.5873C34.2263 41.1175 32.9675 42.3616 31.4095 42.3616L30.8025 42.3616C29.2445 42.3616 27.978 41.1162 27.978 39.5873L27.9282 25.521L21.768 25.521L21.8178 41.1008C21.8178 45.1385 25.1535 48.4143 29.2637 48.4143L32.9419 48.4143C37.0521 48.4143 40.3878 45.1385 40.3878 41.1008L40.3379 25.521L34.1778 25.521L34.1765 25.521Z'
         fill='black'
      />
      <path
         d='M7.77368 32.8088C7.77368 33.3902 8.24144 33.8608 8.81656 33.8608L14.5626 33.8608C17.7053 33.8608 20.2537 36.4301 20.2537 39.6015L20.2537 42.6723C20.2537 45.8424 17.7066 48.413 14.5626 48.413L1.61352 48.413L1.61352 42.1992L13.0494 42.1992C13.6258 42.1992 14.0923 41.7273 14.0923 41.1472L14.0923 41.1292C14.0923 40.5477 13.6245 40.0772 13.0494 40.0772L7.30336 40.0772C4.16066 40.0772 1.61224 37.5078 1.61224 34.3365L1.61224 31.2837C1.61224 28.1136 4.15938 25.543 7.30336 25.543L20.2525 25.543L20.2525 31.7568L8.81656 31.7568C8.24016 31.7568 7.77368 32.2287 7.77368 32.8088Z'
         fill='black'
      />
   </svg>
);
export default logo;
