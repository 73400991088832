import Content from './sections/Content';

const ProjectPage = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default ProjectPage;
